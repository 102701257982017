<template>
    <div>
        <!-- 添加资源 -->
        <div class="box">
            <div class="title_box flex">
                <Title :title="$route.query.id ? '编辑资源' : '添加资源'" />
            </div>
            <div class="ct_box">
                <ul class="flex nav_list">
                    <li @click="routeFn(index)" :class="placard.pub_index == index ? 'active' : ''"
                        v-for="(item, index) in nav_list" :key="index">
                        {{ item.title }}</li>
                </ul>
                <div class="form_box">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Title from '@/components/UserInfoTitle.vue'
import { mapState, mapMutations } from 'vuex'
export default {
    components: {
        Title,
    },
    computed: {
        ...mapState(['placard']),
        ...mapMutations(['SET_PUB_INDEX'])
    },
    mounted() {
        this.getNav()
    },
    data() {
        return {
            nav_list: []
        }
    },
    methods: {
        getNav() {
            let url_arr = []
            this.curlGet('/api/guanggao_info/init').then(res => {
                if (res.data.code) {
                    url_arr = ['/placard_user/add_placard/add_outdoors', '/placard_user/add_placard/add_network', '/placard_user/add_placard/add_journal', '/placard_user/add_placard/add_tv']
                    for (const key in res.data.data) {
                        this.nav_list.push({
                            id: res.data.data[key].id,
                            title: res.data.data[key].title,
                            path: url_arr[key - 1],
                        })
                    }
                }
            })
        },
        routeFn(index) {
            this.$store.commit('SET_PUB_INDEX', index)
            this.$router.push({
                path: this.nav_list[index].path,
            })

        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/scss/AddResource';
</style>